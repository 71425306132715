// eslint-disable-next-line
import { useSelector, useDispatch } from 'react-redux'
import { openCb, closeCb } from '../store/reducers/cbSlice'
// операции с моделью выключателя: включить/ отключить
// отслеживание состояния: включено / отключено
import cbOn from "./../assets/images/cubicleOn.png";
import cbOff from "./../assets/images/cubicleOff.png"
import { setInputs } from '../store/reducers/sepam10Slice';

function ControlPanel() {

    // const cbPosition = false;
    const cbPosition = useSelector((s) => s.cb.rpo);
    const sepamTripped = useSelector((s) => s.sepam10.tripped)
    // console.log(cbPosition)
    
    const operateCB = useDispatch();
    
    return (
        <div
            className="control-panel"
            // onClick={()=>alert("Недоступно в демо-версии.")}
            // disabled
        >
            <img
                src = { cbPosition ? cbOff : cbOn } 
                alt = "здесь располагается функционал панели управления выключателем"
                onClick = {
                    () => {
                        // alert("ControlPanel:", !!cbPosition);
                        if (cbPosition) {
                            if (sepamTripped) {
                                alert("Sepam не квитирован.");
                            } else
                                operateCB(closeCb());                            
                        } else {
                            operateCB(openCb());
                        }
                        operateCB(setInputs(cbPosition));
                    }
                }
            />            
        </div>
    );
}
export default ControlPanel;