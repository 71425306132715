// eslint-disable-next-line
import { useSelector, useDispatch } from "react-redux";
// eslint-disable-next-line
import { useState, useEffect } from "react";

import CurrentInjector from "./current_injector";
import ControlPanel from "./control_panel";
import Device from "./device";
// eslint-disable-next-line
import Test from "./cheking";
// eslint-disable-next-line
import WorkbenchInfo from "./workbenchInfo";


import { tripSepam, setCurrent, setMaximeters, startSepam } from "../store/reducers/sepam10Slice";

function Workbench() {  // здесь собраны компоненты стенда
                        // и логика его работы

  const cbPosition = useSelector((store) => store.cb.rpo);
  // const inputs = useSelector((store) => store.sepam10.inputs);
  // const outputs = useSelector((store) => store.sepam10.outputs);
  // const leds = useSelector((store)=>store.sepam10.leds);
  // const sepamCurrent = useSelector((store) => store.sepam10.current);

  const injectorValue = useSelector((store) => store.injector.value);
  const injectorState = useSelector((store) => store.injector.working);
  const injectorTime = useSelector((store) => store.injector.time);

  const dispatch = useDispatch();

  // логика испытательного стенда (пока что здесь)

  useEffect( () => {
      if ( injectorState && !cbPosition ) { // выдаем ток, если выключатель включен
        dispatch(setCurrent(injectorValue));
        dispatch(setMaximeters(injectorValue))    
        dispatch(startSepam(injectorValue));
      } else {
        dispatch(setCurrent(0));        
        dispatch(startSepam(0));
      }
      if (injectorState && !cbPosition && (injectorValue>200) && (injectorTime>1000))
        dispatch(tripSepam());
    })
  
    

  return (
    <div className="workbench-wrapper">
      {/* <Test /> */}
      {/* <WorkbenchInfo /> */}
      <CurrentInjector />
      <div className="workbench-inner">        
        <Device />                   
        <ControlPanel /> 
      </div>        
    </div>
  );
}
export default Workbench;