// редьюсеры источника
import { createSlice } from "@reduxjs/toolkit"; 

export const injectorSlice = createSlice({
  name: 'injector',
  initialState: {
    value: 0, // величина выдаваемого тока
    time: 0,  // время выдачи (с накоплением)
    working: false,
  },
  reducers: {
    startInjection: (state, action) => {
      state.working = true;
      state.value = action.payload;
    },
    stopInjection: (state) => {
      state.value = 0;
      state.working = false;
    },
    setTime: (state, action) => {
      state.time = action.payload;
    }
      
  }
})
export const { startInjection, stopInjection, setTime } = injectorSlice.actions;

export default injectorSlice.reducer
