// редьюсеры реле Sepam
import { createSlice } from "@reduxjs/toolkit"; 
import { meterings, protections, settings } from "../../components/functions/sepam10"

export const sepam10Slice = createSlice({
  name: 'sepam10',
  initialState: {
    messages: [],
    inputs: [true,false,false,false],
    outputs: [false,false,false,false,false,false],
    leds: [false,false,false,false],
    maximeters: 210,
    started: false,
    tripped: false,
    current: 0,
    time: 0,
    menuMetering: meterings,
    menuProtection: protections,
    menuSetting: settings,
    menuPointer: 0,
  },
  reducers: {
    tripSepam: (state) => {
      state.tripped = true;
      state.outputs[0] = 1;
    },
    addMessage: (state, action) => {
      let numOfMessages = (state.menuMetering.length<4)?1:state.menuMetering.length-2;
      
      state.menuMetering.splice(3,0,{["СОБЫТ" + numOfMessages]: "СОБЫТИЕ = I> "+action.payload})
      
    },
    resetSepam: (state) => { 
      state.tripped = false;
      state.started = false;
      state.outputs[0]=0;      
    },
    resetMaximeters: (state) => {
      state.maximeters = 0;
    },
    setInputs: (state, action) => { // это принимаем положение выключателя
      const inputs = [0,0,0,0];     // пока что используем только первые два
      if (action.payload) {         // входа
        inputs[0]=1;
        inputs[1]=0;
      } else {
        inputs[0]=0;
        inputs[1]=1;
      }      
      state.inputs = inputs;
    },
    setCurrent: (state, action) => {
      state.current = action.payload;
    },
    setMaximeters: (state, action) => {
      if (state.maximeters < action.payload) {
        state.maximeters = action.payload;
      }
    },
    startSepam: (state, action) => {
      if (action.payload >= 250) {      // уставка "по умолчанию"
        state.started = true;        
      } else {
        state.started = false;
      }
    },
    setSepamTime: (state, action) => {      
        state.time = action.payload;           
    },
  }
})
export const { tripSepam, resetSepam, setInputs, resetMaximeters, setCurrent,
                setMaximeters, startSepam, setSepamTime, addMessage
 } = sepam10Slice.actions;

export default sepam10Slice.reducer;