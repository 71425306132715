import { useState } from "react";
import { useEffect } from "react";

// import { useRef } from "react";

const Ticker = ({txt, width = 20, speed = 100}) => {
  
  const [val, setVal] = useState(txt); 

  const s = val.slice(0, width - 1);

  useEffect( () => {
    setVal(txt);
    if (txt.length >= width - 1) {
      const t = setInterval( () => {
        setVal(v => v.slice(1, v.lenght) + v[0])
      }, speed);
      return () => clearInterval(t);
    }
  }, [txt, width, speed])

  
  
  return (
    <div>{s}</div>
  );
}



// function cycleLoopedText(text, width, index) {
// // функция выводит окно в width символов, строка - как кольцо
//   let result = "";
  
//   if (index < text.length - width) {
//     result = text.slice(index, index+width);
//   } else if (index < text.length) {  // если окно заканчивается "за строкой"
//     result = text.slice(index, text.length);
//     result += text.slice(0, index + width - text.length)    
//   }
  
//   return result;
// }
// const Str2 = (props) => {

//   const [currentText, setCurrentText] = useState('');
//   const [currentIndex, setCurrentIndex] = useState(0);
    
//   useEffect(() => {
    
//     if (props.text.length > props.width) {
//       if (currentIndex < props.text.length) {
//         const timeout = setTimeout(() => {          
//           setCurrentIndex(prevIndex => prevIndex + 1);          
//           setCurrentText(cycleLoopedText(props.text, props.width, currentIndex));
//         }, props.delay);
    
//         return () => clearTimeout(timeout);
//       } else { // to next loop
//           setCurrentIndex(0);
//           setCurrentText('');
//       }
//     } else {
//       setCurrentText(props.text);
//     }
//   }, [currentIndex, props.text, props.delay, props.width]);

//   useEffect( () => {
//     setCurrentIndex(0);
//   }, [props.text]);



//   return (
//     <div>{currentText}</div>
//   );  
// }

function Display({str1, str2, str3}) {

  const pointer_position = ["metering", "protection", "settings"][str3]; 
 
  return (
    <div className="display">
        <div className="string1" >
            {str1}            
        </div> 
        <div className="string2" >
            {/* <Str2 text={str2} width={19} delay= {167}/> */}
            <Ticker txt={str2} s={300} />
        </div> 
        <div className={"menu_pointer "+pointer_position}>
            {`\u25C0`}
        </div> 
    </div>  
  )
}
export default Display;