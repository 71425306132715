import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DeviceViewClosed from "./../assets/images/Sepam10.png";
import DeviceViewOpened from "./../assets/images/Sepam10open.png";
import Buttons from "./buttons"; 
import Display from "./particles/display";
import Leds from "./particles/led";
import { arrayOfBooleanToString } from "../components/functions/functions"
import { resetSepam } from "../store/reducers/sepam10Slice";

import { displayDate, displayTime } from "./functions/sepam10";

function Device() {
  let line1 = "";
  let line2 = "";
  //                    I1-РПО I2-РПВ   I3    I4
  // как сделать чтобы вот это все не рендерило при деструктуризации???
  const digitalInputs = useSelector((state) => state.sepam10.inputs);
  const digitalOutputs = useSelector((state) => state.sepam10.outputs);
  const maximeters = useSelector((state) => state.sepam10.maximeters);
  const injectedCurrent = useSelector((state) => state.sepam10.current);
  
  const sepamStarted = useSelector((state) => state.sepam10.started);
  const sepamTripped = useSelector((state) => state.sepam10.tripped);
    
  const meteringMenu = useSelector((state) => state.sepam10.menuMetering);
  const protectionMenu = useSelector((state) => state.sepam10.menuProtection);
  const settingMenu = useSelector((state) => state.sepam10.menuSetting);
  
  const [sepamCover, sepamOpen] = useState(true);
  const [deviceMenu, deviceMenuSelect] = useState({menu:0, submenu:0, direction: 0});
  
  const sepamActions = useDispatch();
  
  const handleAnyButtonClick = (e) => { // обработчик нажатия на кнопку 
    switch(e) {
      case 'm': deviceMenuButtonClick(); break;
      case 'l': deviceSubMenuButtonClick(-1); break;
      case 'r': deviceSubMenuButtonClick(1); break;
      case 'R': sepamActions(resetSepam()); break;
      default: break;
    }
  } 

  function deviceMenuButtonClick() { // перемещение по меню
      let currentMenuPosition = deviceMenu.menu;
      (currentMenuPosition<2)// В Sepam 10: 3 меню
      ?
        currentMenuPosition+=1
      :
        currentMenuPosition=0; 
  
      deviceMenuSelect(
          { menu: currentMenuPosition, submenu:0, direction:0 }
        );      
  }
  
  function deviceSubMenuButtonClick(dir) {   // перемещение в границах подменю
    deviceMenu.direction = dir; 
    
    let currentSubMenuPosition = deviceMenu.submenu;
    let menuMaxCount = 0;
    switch (deviceMenu.menu) {// потом загнать в функцию
      case 0: menuMaxCount = meteringMenu.length - 1; break;
      case 1: menuMaxCount = protectionMenu.length - 1; break;
      case 2: menuMaxCount = settingMenu.length - 1; break;
      default: menuMaxCount = undefined;
    }
    
    if ( currentSubMenuPosition===0 && deviceMenu.direction<0 ) {
      currentSubMenuPosition=menuMaxCount;
    } else if ( currentSubMenuPosition===menuMaxCount && deviceMenu.direction>0 ) {
      currentSubMenuPosition=0;
    } else 
      currentSubMenuPosition+=deviceMenu.direction;

    deviceMenuSelect(      
      {menu: deviceMenu.menu, submenu: currentSubMenuPosition, direction:0});  
  }

  function deviceView(s) {  // определяет, открыта ли крышка у Sepam
    return s ? DeviceViewClosed : DeviceViewOpened;
  }

  let menu = []; // меню может изменяться динамически...

  switch(deviceMenu.menu) {
    case 0:
      menu = meteringMenu;
      break;
    case 1: 
      menu = protectionMenu;
      break;
    case 2: 
      menu = settingMenu;
      break;
    default:
      break;
  }

  line1 = Object.keys(menu[deviceMenu.submenu]).join();
  line2 = Object.values(menu[deviceMenu.submenu]).join();
    
  switch (line2) {
    case "%Date": line2 = displayDate(); break;
    case "%Time": line2 = displayTime(); break;
    case "%Input": line2 = arrayOfBooleanToString(digitalInputs); break;
    case "%Output": line2 = arrayOfBooleanToString(digitalOutputs); break;
    case "%Max": line2 = maximeters + 'A\xA0\xA0' + maximeters + 
      'A\xA0\xA0' + maximeters + 'A\xA0\xA0'; break;
    case "%Current": {
      if (injectedCurrent > 99) {
        line2 = injectedCurrent+'A\xA0'+injectedCurrent+'A\xA0'+injectedCurrent+'A\xA0';
      } else {
        line2 = '\xA0'+injectedCurrent+'A\xA0\xA0\xA0'+injectedCurrent+'A\xA0\xA0\xA0'+injectedCurrent+'A\xA0\xA0\xA0';
      }
      break;
    }
    default: break;
  }

  function speed() { // частота мигания
    if (sepamStarted)
      return 100;
    if (sepamTripped)
      return 690;
    
    return 0;
  }

  useEffect (  () => {
    if (sepamTripped) {
      deviceMenu.menu = 0;
      deviceMenu.submenu = 3;
    }

  }, [sepamTripped] )
  
  return ( 
    <div className = "device_wrapper" >
      
      <img 
        src = { deviceView(sepamCover) } alt = "Sepam10 external view"
        onClick = { () => sepamOpen(!sepamCover) }
        className = "device"
      />
      
      <Buttons
          sepamCover={sepamCover}
          onAnyButtonClick = { handleAnyButtonClick }                
      />
      <Leds ledBlinkSpeed = { speed() } />
      <Display
        str1 = {line1}
        str2 = {line2}
        str3 = {deviceMenu.menu}
      />

    </div>
  );
}
export default Device;