import { useDispatch, useSelector } from "react-redux";

// выдача тока на Device
// учет положения выключателя ???
import { useState, useEffect } from "react";

import { setTime, startInjection, stopInjection } from "../store/reducers/injectorSlice";


import { openCb } from "../store/reducers/cbSlice";
import { tripSepam, addMessage } from "../store/reducers/sepam10Slice"
import { displayDate, displayTime } from "./functions/sepam10";

export default function CurrentInjector() {
    
  const injector = useDispatch();
  const sepam10 = useDispatch();

  const [injection, setStopInjection] = useState(false);  
  const [timer, setTimer] = useState(0);
  const [sepamTimer, setSepamTimer] = useState(0);
  const [maxTimeReached, setMaxTimeReached] = useState(false);

  const cbPosition = useSelector((store) => store.cb.rpo);

  const tick = () => {
    if (!injection) {
      setSepamTimer(0) 
      return;
    }

    if (timer>150) {  // * * *    как отмерять время, если в свернутом состоянии
      setStopInjection(!injection); //  хром стопит таймеры ?? (здесь: неважно)
      document.getElementById('current-select').disabled = !injection; // можно лучче!!
      injector(stopInjection());
      setMaxTimeReached(true);
    }

    let injectedCurrent = document.getElementById('current-select')  // и тут оптимизировать !!!
                                    .value;

    if (sepamTimer==30 && injectedCurrent > 250 && !cbPosition) {
      
      let message = displayDate()+"  "+displayTime()+
                        " IA = "+injectedCurrent + "A IB = "+injectedCurrent+"A IC = "+injectedCurrent +
                        "A I0 = 0A  ----- ";
      sepam10(openCb())
      sepam10(tripSepam());
      sepam10(addMessage(message));

    }


    setTimer(timer+1);
    setSepamTimer(sepamTimer+1);

    injector(setTime(timer));
  };

  useEffect(() => {
    const timerID = setInterval(() => tick(), 10);
    return () => clearInterval(timerID)
  });
  
  return (
    <div
      className="injector"
      title="Здесь располагается функционал  генератора тока.
              Частично недоступно в демо-версии."
    >
      <div className="injector__form" >
        <div className="injector__settings" disabled>  
            <label htmlFor="current-select">Ток</label>
            <select name="current-value" id="current-select">
              <option value="0">0 A</option>
              <option value="100">100 A</option>
              <option value="200">200 A</option>
              <option value="300">300 A</option>
              <option value="400">400 A</option>
              <option value="500">500 A</option>
            </select>            
          <br></br>
          <input
            id="o1"
            type="checkbox"
            className="injector__checkbox"
            value="true"
            disabled
            checked
            />
          Полож. выкл.          
          
        </div>
        <div className="injector__controls" align='center'>
          Время:{ timer / 100 }<br />
          <button onClick = { () => {   
            
            if (!injection) {
              let injectedCurrent = document.getElementById('current-select')
                                    .value;

              injector(startInjection(injectedCurrent));
            } else {
              injector(stopInjection());              
            }

            setStopInjection(!injection);
            document.getElementById('current-select').disabled=!injection;
            }}

            disabled = { maxTimeReached ? true : ""}
          >
            {injection ? 'Стоп' : 'Пуск'}
          </button> 
          <button onClick={() => {
            setTimer(0);
            setMaxTimeReached(false);
            }}>
            
            Сброс
          </button>            
        </div>                        
      </div>
    </div>
  );
}