import { useState, useEffect } from "react";

function Leds( {ledBlinkSpeed} ) {
  const [ledOn, setLedOn] = useState(false);

  const tick = () => {
    if (ledBlinkSpeed===0) {
      setLedOn(false);
      return;
    }  
    setLedOn( (s) => !s);
  }
  
  useEffect( () => {
      const timerID = setInterval( () => tick(), ledBlinkSpeed);
      return () => clearInterval(timerID);
    }
  );  
  // в MVP реализуем только один, самый важный светодиод
  // светодиод срабатывания защиты
  return (
    <div className = "led" id="led" style={ledOn?{opacity: "100%"}:{opacity:"0%"}}>
       {/* лампа рисуется псевдоэлементом */}
    </div> 
  )
}
export default Leds;