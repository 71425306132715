import Button from "./particles/button";
import { useDispatch } from "react-redux";
import { resetMaximeters, resetSepam } from "../store/reducers/sepam10Slice"
// import deviceMenu from "./device";

function Buttons({sepamCover, onAnyButtonClick}) {
    const handleButtonClick = (title) => {
        onAnyButtonClick(title);        
        // console.log('[Buttons] handleClick ', title);
    }        
    const reset = useDispatch();

    return (
        <div>
            <Button title = {"menu"}
                onButtonClick = { handleButtonClick }            
            /> 
            <Button
                title = {"Reset"}
                onButtonClick = { ()=> {
                    reset(resetMaximeters());
                    reset(resetSepam())
                    } 
                }
            />
            <Button
                title = {"left"}
                onButtonClick = { handleButtonClick }
            />
            <Button
                title = {"right"}    
                onButtonClick = { handleButtonClick }                
            />
            { !sepamCover && <Button title = {"plus"}  />}
            { !sepamCover && <Button title = {"minus"} />}
            { !sepamCover && <Button title = {"esc"}   />}
            { !sepamCover && <Button title = {"Enter"} />}
            {/* Большая буква имеет значение! */ }
        
        </div>
    );
}
export default Buttons;