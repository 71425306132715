function arrayOfBooleanToString(array) {
    var string = '';
    array.forEach(element => {
        element?string += '1':string += '0'
    });
    return string;
}
function booleanToString(e) {
    return e?"1":"0";
}

export {arrayOfBooleanToString, booleanToString}