// редьюсеры выключателя
import { createSlice } from "@reduxjs/toolkit"; 

export const cbSlice = createSlice({
  name: 'cb',
  initialState: {
    rpo: false,
    rpv: true,
  },
  reducers: {
    openCb: (state) => {
      state.rpo = true;
      state.rpv = false;
      
      // state.sepam10.inputs[0] = 1;
      // state.sepam10.inputs[1] = 0;
    },
    closeCb: (state) => {
      
      // ДОДЕЛАТЬ ПОТОМ БЛОКИРОВКУ НА ВКЛЮЧЕНИЕ
      //if (!state.sepam10.tripped) {
      state.rpo = false;
      state.rpv = true;
      
        // state.sepam10.inputs[0] = 0;
        // state.sepam10.inputs[1] = 1;
      
    }
  }
})
export const { openCb, closeCb } = cbSlice.actions;

export default cbSlice.reducer