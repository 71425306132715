import { combineReducers, configureStore } from "@reduxjs/toolkit";
import cbReducers from "../store/reducers/cbSlice";
import sepam10Reducers from "../store/reducers/sepam10Slice";
import injectorReducers from "../store/reducers/injectorSlice";

const rootReducer = combineReducers({
    cb: cbReducers,
    sepam10: sepam10Reducers,
    injector: injectorReducers    
})
const store = configureStore({
    reducer: rootReducer,
});

export default store;