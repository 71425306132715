// import { useSelector } from "react-redux";

export function s10Menu() {
    let menuStructure =[];
        
    const {'Metering': menuMetering} = menu;
    const {'Protection': menuProtection} = menu;
    const {'Setting': menuSetting} = menu;

    menuStructure.push(menuMetering, menuProtection, menuSetting);
   
    console.log('menu structure');

    return menuStructure;     
}

export function displayTime() {
    let date = new Date();

    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();
    
    return hour+"Ч "+minute+"МН "+second+"C";
}

export function displayDate() {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth();
    let day = date.getDate();
    let fmonth = ['янв','фев','мар','апр','мая','июн','июл','авг','сен','окт','ноя','дек'];

    return year+" "+fmonth[month]+" "+day;
}

export function s10MenuTemp(url) {
    // не так все просто.
    // структура меню должна соответствовать структурной схеме
    // внутри устройства.
    // В общем виде реализовать возможно, но хз.
    // Пусть пока что будет тупая демка.
    let menuStructure = [];

    let con = fetch(url)
    .then(response => response.text())
    .then(data => {
        
        let options = {  // XMLParser options
            ignoreDeclaration: true,
            ignorePiTags: true,
            ignoreAttributes : false,

            attributeNamePrefix : "@_",
            removeNSPrefix: true
            // preserveOrder: true
            // attributeValueProcessor: (name, val, jPath) => {
            //   // :
            //   return name === "@_name"?val:"";
            // }


        };

        const { XMLParser } = require("fast-xml-parser");
        const parser = new XMLParser(options);
          
        let jObj = parser.parse(data, true);

        console.log(jObj); 

        let s10Menu = jObj.VipamDemoDataFile.MenuInfo.Menu; // деструктурировать!!!
        
        let menuMetering = s10Menu[0];
        menuMetering = menuMetering.SubMenu;
        
        const {'Protection': menuProtection} = s10Menu[1];
        const {'Setting': menuSetting} = s10Menu[2];
    
        menuStructure.push(menuMetering, menuProtection, menuSetting);        
        
    })
    .catch(console.error);

    // let con = await ;
    console.log(`1 ${con}`);

    console.log("Finished.");

    let js = menu; 

    const {'Metering': menuMetering} = js;
    const {'Protection': menuProtection} = js;
    const {'Setting': menuSetting} = js;

    menuStructure.push(menuMetering, menuProtection, menuSetting);

    return menuStructure;    
}

export const protections = [
    {"ФАЗНЫЙ ТТ": "100A / 1A"},
    {"ТТНП": "100A / 1A"},
    {"ЧАСТОТА": "50 Hz"},
    {"I> 51": "ВЫКЛ"},
    {"I>>    50-51":"ВЫД ВР 250А 0.3с"},
    {"Io>    51N":"ВЫКЛ"},
    {"Io>> 50N-51N":"ВЫКЛ"},
    {"ТЕПЛЗАЩ 49 1":"ВЫКЛ"},
    {"ТЕПЛЗАЩ 49 2":"ВЫКЛ"}
];

export const meterings = [
    {"IA IB IC":"%Current"},
    {"I0":"0A"},
    {"ПИК НАГРУЗ":"%Max"}
];

export const settings = [
        {"ЯЗЫК": "РУССКИЙ"},
        {"ОТОБРАЖ I": "IA IB IC"},        
        {"ПИК НАГРУЗ":"5 MN"},
        {"ПРОТОК СВЯЗИ":"MODBUS"},
        {"MODBUS":"5 38400 НЕЧЕТ ВСП"},
        {"ЗАГРУБЛ":"I	ВЫКЛ"},
        {"ЗАГРУБЛ":"Io	ВЫКЛ"},
        {"ВРЕМЯ СБРОСА":"ВЫКЛ"},
        {"КОНТР Ц ОТКЛ":"ВКЛ ВЫКЛЮЧ=ВЫКЛ"},
        {"ДАТА":"%Date"},
        {"ВРЕМЯ":"%Time"},
        {"ЛОГИЧ ВХОДЫ":"ТИП НАПР = ПОСТ ТОК"},
        {"ЛОКАЛ РЕЖИМ":"ПОДТВЕРЖДЕНИЕ ОТКЛ."},
        {"УСТ ПАРОЛЬ":"БЕЗ ПАРОЛЯ"},
        {"СОСТ ВХОДА":"%Input"},
        {"СОСТ ВЫХОДА":"%Output"},
        {"ИНФ О SEPAM":"V1.0"},
        {"НАЗН ВХ/ВЫХ":"СТАНДАРТНЫЙ"}  
];
export function displayLines(menu, submenu) {
    let line1 = "a";
    let line2 = "s";

    return { line1, line2 }
}

// скоро не будет нужно....
const menu = {
    "Metering": [
        {"IA IB IC":"%Current"},
        {"I0":"0A"},
        {"ПИК НАГРУЗ":"%Max"}
    ],
    "Protection": [
        {"ФАЗНЫЙ ТТ": "100A / 1A"},
        {"ТТНП": "100A / 1A"},
        {"ЧАСТОТА": "50 Hz"},
        {"I> 51": "ВЫКЛ"},
        {"I>>    50-51":"ВЫД ВР 250А 0.3с"},
        {"Io>    51N":"ВЫКЛ"},
        {"Io>> 50N-51N":"ВЫКЛ"},
        {"ТЕПЛЗАЩ 49 1":"ВЫКЛ"},
        {"ТЕПЛЗАЩ 49 2":"ВЫКЛ"}
    ],
    "Setting": [
        {"ЯЗЫК": "РУССКИЙ"},
        {"ОТОБРАЖ I": "IA IB IC"},        
        {"ПИК НАГРУЗ":"5 MN"},
        {"ПРОТОК СВЯЗИ":"MODBUS"},
        {"MODBUS":"5 38400 НЕЧЕТ ВСП"},
        {"ЗАГРУБЛ":"I	ВЫКЛ"},
        {"ЗАГРУБЛ":"Io	ВЫКЛ"},
        {"ВРЕМЯ СБРОСА":"ВЫКЛ"},
        {"КОНТР Ц ОТКЛ":"ВКЛ ВЫКЛЮЧ=ВЫКЛ"},
        {"ДАТА":"%Date"},
        {"ВРЕМЯ":"%Time"},
        {"ЛОГИЧ ВХОДЫ":"ТИП НАПР = ПОСТ ТОК"},
        {"ЛОКАЛ РЕЖИМ":"ПОДТВЕРЖДЕНИЕ ОТКЛ."},
        {"УСТ ПАРОЛЬ":"БЕЗ ПАРОЛЯ"},
        {"СОСТ ВХОДА":"%Input"},
        {"СОСТ ВЫХОДА":"%Output"},
        {"ИНФ О SEPAM":"V1.0"},
        {"НАЗН ВХ/ВЫХ":"СТАНДАРТНЫЙ"}  
    ]
}