import Header from "./components/header"
import Workbench from "./components/workbench"
import Footer from "./components/footer"

// import Test from "./components/cheking"

function App() {
  return (
    <div className="App">      
      {/* <Header /> */}
      <Workbench />
      {/* <Footer />       */}
    </div>
  );
}
export default App;
