// import "./../styles/blocks/button.scss";

function Button( {
  onButtonClick = ()=> console.log('Function not detected.'),
  title }  ) {   
  
  const handleButtonClick = (event) => {
    let title = event.target.title;
    // console.log('[Button] Button handleClick ', title);      
    hint && alert(hint);
    onButtonClick(title);
  }
  const hint =
    ["esc", "Enter", "plus", "minus"].includes(title) ?
                  "Недоступно в демо-версии." : null  ;   
  
  return (  
          <div className={"sepambutton "+title} 
            onClick = { handleButtonClick }     
            title = { title[0] }
              // hint /*всплывающая подказка*/}
          >
            {/*первая буква кнопки (Enter, Reset - заглавные)  */}
            { title[0] }              
          </div> 
  
  )
}
export default Button;